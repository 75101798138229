import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { Modal, DatePicker as ADatePicker } from "antd";
import locale from "antd/es/date-picker/locale/zh_CN";
import "moment/locale/zh-cn";

const PopupContainer = styled.div`
    & > div {
        position: relative !important;
    }

    & .date-picker-popup {
        position: relative !important;
        top: 0 !important;
        left: 0 !important;
    }

    & .ant-picker-panel-container{
        box-shadow: unset !important;
    }
`;

export default function DatePicker ({
    disabled,
    value,
    onChange,
    ...props
}) {
    const popupRef = useRef();
    const [visible, setVisible] = useState(false);
    const [localValue, setLocalValue] = useState(value);

    const handleOk = () => {
        setVisible(false);
        onChange(localValue);
    };

    const handleCancel = () => {
        setVisible(false);
        setLocalValue(value);
    };

    useEffect(() => {
        setLocalValue(value);
    }, [value]);

    return (
        <>
            <ADatePicker
                showToday={false}
                locale={locale}
                {...props}
                disabled={disabled}
                open={visible}
                allowClear={false}
                value={localValue}
                inputReadOnly={true}
                popupClassName="date-picker-popup"
                popupStyle={{
                    position: "relative",
                    left: 0,
                    top: 0,
                }}
                getPopupContainer={() => popupRef.current}
                onClick={() => setVisible(!disabled && true)}
                onChange={setLocalValue}
            />
            <Modal
                open={visible}
                closable={false}
                width={277}
                bodyStyle={{
                    padding: 0,
                }}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <PopupContainer ref={popupRef} />
            </Modal>
        </>
    );
}