import { configureStore } from "@reduxjs/toolkit";

import authReducer from "./reducers/auth";
import userReducer from "./reducers/user";
import viewpointReducer from "./reducers/viewpoint";

export default configureStore({
    reducer: {
        auth: authReducer,
        user: userReducer,
        viewpoint: viewpointReducer,
    },
});