import React, {useEffect, useMemo, useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {cloneDeep, debounce} from "lodash";
import {Button, Checkbox, message, Select} from "antd";
import moment from "moment";

import {Col, Page, Row, Text} from "@/components/Base";
import DatePicker from "@/components/DatePicker";
import Cascader from "@/components/Cascader";

import {viewpoint} from "@/requests";

import {INTERVAL_OPTIONS, PICKER_CONFIG} from "@/pages/viewpoints/constants";
import {useRequest} from "ahooks";
import {ViewpointRoleMap} from "@/utils/constant";
import {formatPermissionTree} from "@/utils/viewpoint";
import {findTreeNodeByKey} from "@/utils/tree";
import {Cascader as OCascader} from "antd-mobile";

export default function ViewPointsEditMetaApp () {
    const navigate = useNavigate();
    const location = useLocation();
    const viewPointId = useParams().id;

    const [loading, setLoading] = useState(false);

    // 选项
    const [categories, setCategories] = useState([]);
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [reportOptions, setReportOptions] = useState([]);

    // 表单
    const [category, setCategory] = useState([]);
    const [report, setReport] = useState([]);
    const [role, setRole] = useState();

    const [detail, setDetail] = useState({
        "reportId": null,
        "reportPath": [],
        "title": "",
        "content": "",
        "img": "",
        "mp3": "",
        "interval": "",
        "variety": null,
        "type": null,
        "date": "",
        "createdAt": "",
        "creatorId": null,
        "updatedAt": "",
        "updatedBy": null,
        "status": 0, // 0 created, 1 published, 2 deleted
        "questionId": null,
        "creator": "陈 亮",
        "updater": "陈 亮",
        "reportName": null,
    });

    const setSingleDetail = (key, value) => {
        setDetail((prev) => {
            return {
                ...prev,
                [key]: value,
            };
        });
    };

    // 获取观点详情
    useEffect(() => {
        if (String(location.state?.viewpointDetail?.id) === String(viewPointId)) {
            // 使用 location.state.viewpointDetail 作为 detail
            const reportPath = JSON.parse(location.state.viewpointDetail.reportPath || "[]");
            setDetail({
                ...location.state.viewpointDetail,
                reportPath: reportPath,
            });

            if (reportPath?.length || location.state.viewpointDetail?.reportId) {
                setReport([
                    ...reportPath,
                    location.state.viewpointDetail.reportId,
                ]);
            }
            setCategory([
                location.state.viewpointDetail.variety,
                location.state.viewpointDetail.type,
            ]);
            setRole(location.state.viewpointDetail.role);
        } else {
            // fallback
            viewpoint.detail(viewPointId)
                .then((res) => {
                    const reportPath = JSON.parse(res.reportPath || "[]");
                    setDetail({
                        ...res,
                        reportPath: reportPath,
                    });
                    if (reportPath?.length || res?.reportId) {
                        setReport([
                            ...reportPath,
                            res.reportId,
                        ]);
                    }
                    setCategory([
                        res.variety,
                        res.type,
                    ]);
                    setRole(res.role);
                })
                .catch((e) => {
                    console.error(e);
                    message.error("观点详情获取失败");
                });
        }
    }, [viewPointId]);

    // 根据 report 反向生成 reportPath 和 reportId
    useEffect(() => {
        if (report?.length) {
            const reportId = report[report.length - 1];
            const reportPath = report.slice(0, report.length - 1);
            setSingleDetail("reportId", reportId);
            setSingleDetail("reportPath", reportPath);
        } else {
            setSingleDetail("reportId", null);
            setSingleDetail("reportPath", []);
        }
    }, [report]);

    // 根据 category 反向生成 variety 和 type
    useEffect(() => {
        console.log("category", category);
        if (category?.length) {
            setSingleDetail("variety", category[0]);
            setSingleDetail("type", category[1]);
        } else {
            setSingleDetail("variety", null);
            setSingleDetail("type", null);
        }
    }, [category]);

    // 动态加载报告
    const loadReports = useMemo(() => debounce(selectedOptions => {
        const targetId =
            selectedOptions[selectedOptions.length - 1];
        const targetOption = findTreeNodeByKey({value: targetId, treeData: reportOptions});
        setReportOptions(() => OCascader.optionSkeleton);
        if (targetOption?.children) {
            const tempChildren = targetOption?.children || [];
            targetOption.children = OCascader.optionSkeleton;
            setReportOptions(() => [...reportOptions]);
            viewpoint.getReportList({
                collectionId: targetId,
            })
                .then(res => {
                    targetOption.children = res.rows.reduce((old, report) => {
                        if (
                            !old.some(
                                (item) =>
                                    item.value === report.id,
                            )
                        ) {
                            old.push({
                                value: report.id,
                                label: report.name,
                                isLeaf: true,
                            });
                        }
                        return old;
                    }, cloneDeep(tempChildren));
                    setReportOptions(() => [...reportOptions]);
                })
                .catch(error => {
                    console.log(error);
                    message.error("获取报告列表失败");
                });
        }
    }, 500), [reportOptions]);

    useEffect(() => {
        loadReports(detail.reportPath);
    }, [detail.reportPath, categories]);

    const {
        data: searchOptionsData,
    } = useRequest(() => viewpoint.getReportTypeList({
        type: "AUTH_TYPE_VIEWPOINT",
    }));

    const roleSelectOptions = useMemo(() => {
        return searchOptionsData?.data?.roleList?.map((v) => ({
            label: ViewpointRoleMap[v],
            value: v,
        }));
    }, [searchOptionsData]);

    // 获取分类
    useEffect(() => {
        viewpoint
            .getCollectionPermissionTreeForSave({type: "AUTH_TYPE_VIEWPOINT"})
            .then((res) => {
                setCategoryOptions(formatPermissionTree(res?.data || []));
            });
        viewpoint.getCollectionPermissionTreeForSave({type: "AUTH_TYPE_DASHBOARD"})
            .then(res => {
                setCategories(res);
                setReportOptions(formatPermissionTree(res?.data || [], {withSkeleton: true}));
            });
    }, []);

    const handleSave = async () => {
        setLoading(true);
        const title = `${detail.interval.includes("daily")
            ? moment(detail.date).locale("zh-cn").format("YYYY-MM-DD")
            : detail.interval === "weekly"
                ? `${moment(detail.date).locale("zh-cn").format("YYYY-MM-DD")}-${moment(detail.date).locale("zh-cn").add(7, "days").format("YYYY-MM-DD")}`
                : detail.interval === "monthly"
                    ? moment(detail.date).locale("zh-cn").format("YYYY-MM")
                    : moment(detail.date).locale("zh-cn").format("YYYY")
            }-${categoryOptions.find(c => c.value === category[0]).label}-${categoryOptions.find(c => c.id === category[0]).children.find(collection => collection.id === category[1]).label}`;
        try {
            await viewpoint.update({
                ...detail,
                id: viewPointId,
                title,
                reportPath: JSON.stringify(detail.reportPath),
                classification: detail.type,
                isCopy: detail.isCopy || 0,
                role,
            });
            navigate(`/viewpoints/${viewPointId}`);
            message.success("保存成功");
        } catch (e) {
            console.error(e);
            message.error("保存失败");
        } finally {
            setLoading(false);
        }
    };


    return (
        <Page permission="viewpoint:update" align="flex-start">
            <Row gap={8} align="center" justify="flex-start" padding={[12, 0]} width="auto" onClick={() => navigate(`/viewpoints/${viewPointId}`)}>
                <FontAwesomeIcon icon={solid("angle-left")} fixedWidth style={{ position: "relative", top: 1, fontSize: 20 }} />
                <Text weight="bold" size={20}>编辑观点细节</Text>
            </Row>
            <Col gap={8} justify="center">
                <Text weight="bold" size={14} noShrink>
                    选择类型
                    <Text color="red">*</Text>
                </Text>
                <Select
                    style={{ width: "100%" }}
                    options={INTERVAL_OPTIONS}
                    value={detail.interval}
                    onChange={(value) => setSingleDetail("interval", value)}
                />
            </Col>
            <Col gap={8} justify="center">
                <Text weight="bold" size={14} noShrink>
                    选择日期
                    <Text color="red">*</Text>
                </Text>
                <DatePicker
                    style={{ width: "100%" }}
                    picker={PICKER_CONFIG[detail.interval].picker}
                    value={moment(detail.date)}
                    onChange={(date) => {
                        setSingleDetail("date", date.startOf(PICKER_CONFIG[detail.interval].format).format("YYYY-MM-DD"));
                    }}
                />
            </Col>
            <Col gap={8} justify="center">
                <Text weight="bold" size={14} noShrink>
                    选择角色
                    <Text color="red">*</Text>
                </Text>
                <Select
                    placeholder="请选择角色"
                    style={{
                        width: "100%",
                    }}
                    options={roleSelectOptions}
                    value={role}
                    onChange={setRole}
                />
            </Col>
            <Col gap={8} justify="center">
                <Text weight="bold" size={14} noShrink>
                    选择品种
                    <Text color="red">*</Text>
                </Text>
                <Cascader
                    changeOnSelect
                    placeholder="请选择品种"
                    style={{
                        width: "100%",
                    }}
                    options={categoryOptions}
                    value={category}
                    onChange={setCategory}
                />
            </Col>
            <Col gap={8} justify="center">
                <Text weight="bold" size={14} noShrink>
                    关联报告
                </Text>
                <Cascader
                    changeOnSelect
                    placeholder="请选择报告"
                    style={{ width: "100%" }}
                    options={reportOptions}
                    value={report}
                    onChange={setReport}
                    loadData={loadReports}
                />
            </Col>
            <Row justify="flex-end">
                <Checkbox
                    checked={detail.isCopy === 1 ? true : false}
                    onChange={e => setSingleDetail("isCopy", e.target.checked ? 1 : 0)}
                >
                    复制报告内容
                </Checkbox>
            </Row>
            <Row justify="flex-end">
                <Button loading={loading} type="primary" style={{ width: "100%" }} onClick={handleSave}>更新</Button>
            </Row>
        </Page>
    );
}