import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
    name: "auth",
    initialState: {
        loggedIn: false,
        token: null,
    },
    reducers: {
        login: (state, action) => {
            state.loggedIn = true;
            state.token = action.payload;
        },
        logout: (state) => {
            state.loggedIn = false;
            state.token = null;
        },
    },
});

export const { login, logout } = authSlice.actions;

export default authSlice.reducer;