export const INTERVAL_OPTIONS = [
    {
        label: "日度/晨会",
        value: "daily-morning",
    },
    {
        label: "日度/EOD",
        value: "daily-eod",
    },
    {
        label: "周度",
        value: "weekly",
    },
    {
        label: "月度",
        value: "monthly",
    },
    {
        label: "年度",
        value: "annual",
    },
];

export const PICKER_CONFIG = {
    "": {
        format: "day",
        picker: "date",
    },
    "daily-morning": {
        format: "day",
        picker: "date",
    },
    "daily-eod": {
        format: "day",
        picker: "date",
    },
    weekly: {
        format: "week",
        picker: "week",
    },
    monthly: {
        format: "month",
        picker: "month",
    },
    annual: {
        format: "year",
        picker: "year",
    },
};