import { connect } from "react-redux";
import { Navigate } from "react-router-dom";

const mapStateToProps = (state) => ({
    loggedIn: state.auth.loggedIn,
});

const Index = ({ loggedIn }) => {
    if (loggedIn) {
        return <Navigate to="/viewpoints" />;
    }
    return <Navigate to="/auth/login" />;
};

export default connect(mapStateToProps)(Index);