import React, { useState, useEffect, useMemo } from "react";
import { Button, Spin, Empty, message } from "antd";
import { InfiniteScroll } from "antd-mobile";
import { debounce } from "lodash";

import withRouter from "@/components/withRouter";
import FeaturePermission from "@/components/Hoc/FeaturePermission";

import { connect } from "react-redux";
import {
    setLoading,
    setTotal,
    setList,
    setCategories,
    setFilter,
    setSingleFilter,
} from "@/store/reducers/viewpoint";

import { Page, Row, Col, Text } from "@/components/Base";
import Filter from "./components/Filter";
import ViewpointCard from "./components/ViewpointCard";

import { viewpoint } from "@/requests";

const mapStateToProps = (state) => ({
    viewpoint: state.viewpoint,
});

const mapDispatchToProps = {
    setLoading,
    setTotal,
    setList,
    setCategories,
    setFilter,
    setSingleFilter,
};

const ViewPoints = function ({ router, viewpoint: storeViewpoint, ...props }) {
    const { setLoading, setTotal, setList, setCategories, setFilter, setSingleFilter } = props;
    const { loading, total, list, categories, filter, varieties, classifications } = storeViewpoint;

    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [hasMore, setHasMore] = useState(true);

    // 获取分类
    const refreshCategories = useMemo(() => debounce(() => {
        setLoading(true);
        viewpoint
            .getCategories()
            .then(setCategories)
            .finally(() => setLoading(false));
    }, 500), []);

    // 刷新列表
    const refreshList = useMemo(() => debounce((filter) => {
        setLoading(true);
        viewpoint
            .getMyList({
                ...filter,
                startDate: filter.dateRange?.[0],
                endDate: filter.dateRange?.[1],
            })
            .then((list) => {
                setTotal(list.totalRows);
                setList(list.rows);
            })
            .finally(() => setLoading(false));
    }, 500), [list]);

    // 加载更多
    const loadMore = async () => {
        setLoading(true);
        setPage(page + 1);
        try {
            const newList = await viewpoint
                .getMyList({
                    ...filter,
                    startDate: filter.dateRange?.[0],
                    endDate: filter.dateRange?.[1],
                    page: page + 1,
                    pageSize,
                });
            setTotal(newList.totalRows);
            setList([
                ...list,
                ...newList.rows,
            ]);
        } catch (e) {
            console.error(e);
            message.error("加载失败");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (categories.length === 0) {
            refreshCategories();
        }
    }, []);

    useEffect(() => {
        if (!loading) {
            setPage(1);
            refreshList({
                ...filter,
                page: 1,
                pageSize,
            });
        }
    }, [filter]);

    useEffect(() => {
        if (list.length >= total) {
            setHasMore(false);
        } else {
            setHasMore(true);
        }
    }, [list]);

    return (
        <Page permission="viewpoint:query">
            <Row align="center" justify="space-between" noShrink>
                <Text weight="bold" size={20}>我的观点</Text>
                <FeaturePermission permission="viewpoint:insert">
                    <Button type="primary" onClick={() => router.navigate("/viewpoints/create")}>创建观点</Button>
                </FeaturePermission>
            </Row>

            <Filter
                filter={filter}
                varieties={varieties}
                classifications={classifications}
                onChange={setFilter}
                setSingleFilter={setSingleFilter}
            />

            <Spin spinning={loading} tip="正在载入...">
                <Col gap={12}>
                    {list.map((item) => (
                        <ViewpointCard
                            key={item.id}
                            {...item}
                            onUpdate={() => refreshList({
                                ...filter,
                                page: 1,
                                pageSize: list.length,
                            })}
                            onGoToViewpoint={() => router.navigate(`/viewpoints/${item.id}`, {
                                state: {
                                    viewpointDetail: item,
                                },
                            })}
                        />
                    ))}
                    {list.length === 0 && <Empty />}
                </Col>
            </Spin>

            {
                list.length > 0 && <InfiniteScroll
                    initialLoad={false}
                    pageStart={1}
                    loadMore={loadMore}
                    hasMore={hasMore}
                    useWindow={false}
                />
            }
        </Page>
    );
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ViewPoints));