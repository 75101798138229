/**
 * 获取树状数据的子节点
 * @param {{
 *   value: string | number;
 *   key?: string;
 *   treeData: Record<string, any>[];
 *   childrenKey?: string;
 * }} param0
 */
export const findTreeNodeByKey = ({
  value,
  key = "id",
  treeData,
  childrenKey = "children",
}) => {
  const tempTree = [...treeData];
  while (tempTree.length) {
    const node = tempTree.shift();
    if (node?.[key] === value) {
      return node;
    }
    if (node?.[childrenKey]) {
      tempTree.push(...node[childrenKey]);
    }
  }
};
