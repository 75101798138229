import React from "react";
import ReactDOM from "react-dom/client";

import "@/styles/app.less";
import "@/styles/antd.less";
import "@/styles/antd-mobile.less";

import { ConfigProvider } from "antd";
import zh_CN from "antd/es/locale/zh_CN";

import App from "@/routes";
import { Provider } from "react-redux";

import store from "@/store";
import { login } from "@/store/reducers/auth";
import { setUserInfo } from "@/store/reducers/user";

import { user } from "@/requests";

if (
    (process.env.NODE_ENV && process.env.NODE_ENV === "development") ||
    window.location.search.indexOf("showConsole") > -1
) {
    // 载入 VConsole
    const VConsole = require("vconsole");
    new VConsole();
}

// 检查登录状态
user.getUserInfo()
    .then((userinfo) => {
        if (userinfo?.id) {
            // 已有登录状态，更新用户信息
            store.dispatch(login("user")); // session cookie 是 httpOnly 的，无法通过 js 获取
            store.dispatch(setUserInfo(userinfo));
        } else {
            // 无登录状态，不做处理
        }
    })
    .catch(() => {
        // 无登录状态，不做处理
    })
    .finally(() => {
        const root = ReactDOM.createRoot(document.getElementById("root"));
        root.render(
            <React.StrictMode>
                <Provider store={store}>
                    <ConfigProvider locale={zh_CN}>
                        <App />
                    </ConfigProvider>
                </Provider>
            </React.StrictMode>
        );
    });