import React, { useState } from "react";
import moment from "moment";
import { Modal, DatePicker, Button } from "antd";
import { Calendar } from "antd-mobile";
import locale from "antd/es/date-picker/locale/zh_CN";
import "moment/locale/zh-cn";

import { Row } from "./Base";

const { RangePicker } = DatePicker;

export default function DateRangePicker ({
    disabled,
    value,
    onChange,
    ...props
}) {
    const [visible, setVisible] = useState(false);
    const [dateRange, setDateRange] = useState([
        value?.[0] ? value[0].toDate() : undefined,
        value?.[1] ? value[1].toDate() : undefined,
    ]);
    const [renderCalendar, setRenderCalendar] = useState(true);

    const handleOk = () => {
        setVisible(false);
        onChange([
            dateRange?.[0] ? moment(dateRange[0]) : undefined,
            dateRange?.[1] ? moment(dateRange[1]) : undefined,
        ]);
    };

    const handleCancel = () => {
        setVisible(false);
        setDateRange([
            value?.[0] ? value[0].toDate() : undefined,
            value?.[1] ? value[1].toDate() : undefined,
        ]);
    };

    const handleReset = () => {
        setDateRange([undefined, undefined]);
        // 重新渲染 Calendar 组件
        // 因为其会记录点击状态，不重新渲染可能会导致意外的日期选择
        setRenderCalendar(false);
        setTimeout(() => setRenderCalendar(true), 0);
    };

    return (
        <>
            <RangePicker
                {...props}
                open={false}
                allowClear={false}
                value={value}
                inputReadOnly={true}
                locale={locale}
                onClick={() => setVisible(true)}
            />
            <Modal
                open={visible}
                closable={false}
                onOk={handleOk}
                onCancel={handleCancel}
                destroyOnClose={true}
                footer={(
                    <Row justify="space-between" gap={6}>
                        <Button onClick={handleReset}>重置</Button>
                        <Row width="auto" gap={6}>
                            <Button onClick={handleCancel}>关闭</Button>
                            <Button type="primary" onClick={handleOk}>确定</Button>
                        </Row>
                    </Row>
                )}
            >
                {renderCalendar && (
                    <Calendar
                        value={dateRange}
                        selectionMode="range"
                        onChange={setDateRange}
                    />
                )}
            </Modal>
        </>
    );
}