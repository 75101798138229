import React, { useMemo } from "react";
import { Modal, message } from "antd";
import moment from "moment";

import { Col, Row, Text } from "@/components/Base";
import FeaturePermission from "@/components/Hoc/FeaturePermission";

import { viewpoint } from "@/requests";

export default function ViewpointCard ({
    id,
    title,
    content,
    date,
    createdAt,
    status,
    reportName,
    permission,
    onUpdate,
    onGoToViewpoint,
    ...props
}) {
    const pureText = useMemo(() => {
        const replaced = content?.replace(/<[^>]+>/g, "");
        return replaced?.length ? replaced : "此观点没有可显示的内容";
    }, [content]);

    const createdAtText = useMemo(() => {
        return moment(createdAt).format("YYYY-MM-DD HH:mm");
    }, [createdAt]);

    const toggleViewpointPublish = () => {
        Modal.confirm({
            title: `确认${status === 1 ? "取消发布" : "发布"}此观点？`,
            onOk: async (close) => {
                try {
                    const action = status === 1 ? "unpublish" : "publish";
                    await viewpoint[action](id, props.reportId);
                    message.success("操作成功");
                } catch (e) {
                    console.error(e);
                    message.error("操作失败");
                }
                onUpdate();
                close();
            },
        });
    };

    const deleteViewpoint = () => {
        Modal.confirm({
            title: "确认删除此观点？此操作无法被撤销！",
            onOk: async (close) => {
                try {
                    await viewpoint.delete(id);
                    message.success("操作成功");
                } catch (e) {
                    console.error(e);
                    message.error("操作失败");
                }
                onUpdate();
                close();
            },
        });
    };

    return (
        <Col gap={12} width="100%" background="var(--main-bg-color)" padding={12} radius={4} border="1px solid var(--hover-bg-color)">
            <Row gap={8} justify="space-between" align="center">
                <Text size={16} weight="bold" width={0} grow noWrap ellipsis onClick={onGoToViewpoint}>{title}</Text>
                {permission === 2 && <Row gap={8} width="auto" justify="center" align="center">
                    <FeaturePermission permission="viewpoint:update">
                        <Text
                            size={14}
                            cursor="pointer"
                            color="var(--accent-text-color)"
                            onClick={toggleViewpointPublish}
                        >{status === 0 ? "发布" : "取消发布"}</Text>
                    </FeaturePermission>
                    <FeaturePermission permission="viewpoint:delete">
                        <Text
                            size={14}
                            cursor="pointer"
                            color="var(--accent-text-color)"
                            onClick={deleteViewpoint}
                        >删除</Text>
                    </FeaturePermission>
                </Row>}
            </Row>
            <Text size={14} overflow="hidden">{pureText}</Text>
            <Row gap={8} justify="space-between" align="center">
                <Text size={12}>{createdAtText}</Text>
                {reportName && (<Text size={12} width="0" align="right" grow noWrap ellipsis>关联报告:{reportName}</Text>)}
            </Row>
        </Col>
    );
}