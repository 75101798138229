import { useEffect, useState } from "react";
import styled from "styled-components";

const Container = styled.div`
  & video,
  & img {
    width: 100% !important;
    height: auto !important;
  }
`;

export default function RichTextPreview ({
    text,
}) {
    const [html, setHtml] = useState("");

    useEffect(() => {
        setHtml(text);
    }, [text]);

    return (
        <Container
            dangerouslySetInnerHTML={{ __html: html }}
        />
    );
}