import { Cascader as OCascader } from "antd-mobile";
/**
 * 转换品种的下拉选项
 * @param data
 * @param {{
 *     label: string;
 *     value: string;
 *     withSkeleton: boolean;
 * }?} optionKeyMap
 * @returns {*}
 */
export const formatPermissionTree = (
    data,
    optionKeyMap
) => {
    return data
        ?.filter?.((item) => item.isShow)
        ?.sort((a, b) => b?.orderNo - a?.orderNo)
        ?.map?.((item) => {
            const newItem= {
                ...item,
                [optionKeyMap?.label || "label"]: item.name,
                [optionKeyMap?.value || "value"]: item.id,
                children: formatPermissionTree(item.children, optionKeyMap) || (optionKeyMap?.withSkeleton && OCascader.optionSkeleton),
            };
            optionKeyMap?.transKey?.forEach?.((keyItem) => {
                // @ts-ignore
                newItem[keyItem.label] = item[keyItem.value];
            });
            return newItem;
        });
};