import { useState } from "react";
import { cloneDeep } from "lodash";
import { Cascader as DesktopCascader, message } from "antd";
import { Cascader as MobileCascader } from "antd-mobile";

const isSelectedLeaf = (options, value) => {
    let selectedItemHasChildren = true;
    let selectedItem = cloneDeep(options);
    for (const index of value) {
        selectedItem = (selectedItem?.children || selectedItem).find((item) => item.value === index);
    }
    selectedItemHasChildren = selectedItem?.children !== undefined;
    return !selectedItemHasChildren;
};

export default function Cascader ({
    options,
    value,
    onChange,
    loadData,
    allowSelectLeaf = false,
    ...props
}) {
    const [loadedKeys, setLoadedKeys] = useState([]);
    const [mobileVisible, setMobileVisible] = useState(false);

    const handleMobileConfirm = (value) => {
        if (!allowSelectLeaf && !isSelectedLeaf(options, value)) {
            message.error("请选择到最后一级");
            return;
        }
        setMobileVisible(false);
        onChange(value);
    };

    return (
        <>
            <DesktopCascader
                {...props}
                open={false}
                allowClear={false}
                options={options}
                value={value}
                onClick={() => setMobileVisible(true)}
            />
            <MobileCascader
                visible={mobileVisible}
                options={options}
                value={value}
                onSelect={(value) => {
                    if (loadedKeys.includes(value[value.length - 1])) return;
                    setLoadedKeys([...loadedKeys, value[value.length - 1]]);
                    loadData(value);
                }}
                onConfirm={handleMobileConfirm}
                onCancel={() => setMobileVisible(false)}
            />
        </>
    );
}