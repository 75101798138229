import React from "react";
import {
    BrowserRouter as Router,
    Routes,
    Route,
} from "react-router-dom";

/* 布局 */
import AuthLayout from "@/components/Layouts/Auth";
import UserLayout from "@/components/Layouts/User";

/* 包装器 */
import Titled from "@/components/Page/Titled";

/* 页面组件 */
import Unauthorized from "@/pages/401";
import NotFound from "@/pages/404";
import IndexApp from "@/pages/index/IndexApp";
import LoginApp from "@/pages/auth/login/LoginApp";
import LogoutApp from "@/pages/auth/logout/LogoutApp";
import ViewPointsApp from "@/pages/viewpoints/index/ViewPointsApp";
import ViewPointsDetailApp from "@/pages/viewpoints/_id/index/ViewPointsDetailApp";
import ViewPointsEditApp from "@/pages/viewpoints/_id/edit/ViewPointsEditApp";
import ViewPointsEditMetaApp from "@/pages/viewpoints/_id/edit-meta/ViewPointsEditMetaApp";
import ViewPointsCreateApp from "@/pages/viewpoints/create/ViewPointsCreateApp";

export default function App () {
    return (
        <Router>
            <Routes>
                {/* 鉴权路由 */}
                <Route path="auth" element={<AuthLayout />}>
                    <Route path="login" element={<Titled title="登录" children={<LoginApp />} />} />
                    <Route path="logout" element={<Titled title="登出" children={<LogoutApp />} />} />
                </Route>
                {/* 用户路由 */}
                <Route path="/" element={<UserLayout />}>
                    {/* 首页 */}
                    <Route path="/" element={<Titled title="首页" children={<IndexApp />} />} />
                    {/* 观点库 */}
                    <Route path="viewpoints">
                        <Route path="/viewpoints/" element={<Titled title="观点库" children={<ViewPointsApp />} />} />
                        <Route path="/viewpoints/:id" element={<Titled title="观点详情" children={<ViewPointsDetailApp />} />} />
                        <Route path="/viewpoints/:id/edit" element={<Titled title="编辑观点" children={<ViewPointsEditApp />} />} />
                        <Route path="/viewpoints/:id/edit-meta" element={<Titled title="编辑观点细节" children={<ViewPointsEditMetaApp />} />} />
                        <Route path="/viewpoints/create" element={<Titled title="创建观点" children={<ViewPointsCreateApp />} />} />
                    </Route>
                </Route>
                {/* 401 */}
                <Route path="/401" element={<Titled title="401" children={<Unauthorized />} />} />
                {/* 404 */}
                <Route path="*" element={<Titled title="404" children={<NotFound />} />} />
            </Routes>
        </Router>
    );
}