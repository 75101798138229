import React from "react";
import { Select, Radio } from "antd";
import moment from "moment";

import { Row, Col, Text } from "@/components/Base";
import DateRangePicker from "@/components/DateRangePicker";

import { INTERVAL_OPTIONS } from "@/pages/viewpoints/constants";

export default function Filter ({ filter, varieties, classifications, onChange, setSingleFilter }) {

    const handleSingleChange = (key, value) => {
        setSingleFilter({ key, value });
        setSingleFilter({
            key: "page",
            value: 1,
        });
    };

    const intervalOptions = [
        {
            label: "全部",
            value: "",
        },
        ...INTERVAL_OPTIONS,
    ];

    return (
        <Col gap={12} noShrink>
            <Row align="center" gap={8}>
                <Row align="center" gap={8}>
                    <Col gap={4}>
                        <Text weight="bold" size={14} noShrink>观点类型</Text>
                        <Select
                            style={{ width: "100%" }}
                            value={filter.interval}
                            options={intervalOptions}
                            onChange={(interval) => handleSingleChange("interval", interval)}
                        />
                    </Col>
                    <Col gap={4}>
                        <Text weight="bold" size={14} noShrink>商品品种</Text>
                        <Select
                            style={{ width: "100%" }}
                            value={filter.variety?.[0] ?? ""}
                            options={varieties}
                            onChange={(variety) => handleSingleChange("variety", variety ? [variety] : [])}
                        />
                    </Col>
                    <Col gap={4}>
                        <Text weight="bold" size={14} noShrink>商品分类</Text>
                        <Select
                            style={{ width: "100%" }}
                            value={filter.classification?.[0] ?? ""}
                            options={classifications}
                            onChange={(classification) => handleSingleChange("classification", classification ? [classification] : [])}
                        />
                    </Col>
                </Row>
            </Row>

            <Row align="center" gap={8}>
                <Text weight="bold" size={14} noShrink>选择日期</Text>
                <DateRangePicker
                    style={{
                        width: "100%",
                    }}
                    value={filter.dateRange.filter(Boolean).map(i => moment(i))}
                    onChange={(dateRange) => handleSingleChange("dateRange", [dateRange?.[0]?.format("YYYY-MM-DD"), dateRange?.[1]?.format("YYYY-MM-DD")])}
                />
            </Row>

            <Row align="center" gap={8}>
                <Text weight="bold" size={14} noShrink>排序方式</Text>
                <Radio.Group
                    style={{
                        width: "100%",
                    }}
                    value={filter.sort}
                    onChange={(e) => handleSingleChange("sort", e.target.value)}
                >
                    <Radio.Button value="created">创建时间</Radio.Button>
                    <Radio.Button value="date">观点时间</Radio.Button>
                </Radio.Group>
            </Row>
        </Col>
    );
}