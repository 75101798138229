import React from "react";
import { Slider, Popover } from "antd";
import styled from "styled-components";
import {
    PlayCircleOutlined,
    PauseCircleOutlined,
    SoundOutlined,
    HistoryOutlined,
} from "@ant-design/icons";

const Container = styled.div`
  display: flex;
  position: relative;
  height: 54px;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  padding: 4px 8px;
  border-radius: 4px;
  background-color: #fff;
  box-sizing: border-box;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  transition: box-shadow 0.2s ease 0s;
  gap: 8px;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
`;

const Row = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  gap: 8px;
`;


export default class AudioPlayer extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            id: Math.random(),
            audio: null,
            paused: true,
            duration: 0,
            progress: 0,
            volume: 1,
            playbackRate: 1,
        };
    }

    createAudioObject = () => {
        const {
            src,
            initialTime,
            autoplay,
            onTimeUpdate,
            onTogglePlayState,
        } = this.props;
        const audio = new Audio(src);

        audio.addEventListener("timeupdate", () => {
            if (typeof onTimeUpdate === "function") {
                onTimeUpdate(audio.currentTime);
            }
            this.setState({
                progress: audio.currentTime / audio.duration,
            });
        });

        audio.addEventListener("loadedmetadata", () => {
            if (initialTime) {
                audio.currentTime = initialTime;
            }

            if (autoplay) {
                audio.play();
            }

            this.setState({
                duration: audio.duration,
            });
        });

        audio.addEventListener("play", () => {
            this.setState({
                paused: false,
            });
            if (typeof onTogglePlayState === "function") {
                onTogglePlayState(true);
            }
        });

        audio.addEventListener("pause", () => {
            this.setState({
                paused: true,
            });
            if (typeof onTogglePlayState === "function") {
                onTogglePlayState(false);
            }
        });

        return audio;
    };

    componentDidMount () {
        this.setState({
            audio: this.createAudioObject(),
        });
    }

    componentDidUpdate (prevProps) {
        if (this.props.src !== prevProps.src) {
            if (this.state.audio) {
                this.state.audio.pause();
                this.setState({
                    audio: this.createAudioObject(),
                });
            }
        } else if (this.props.initialTime !== prevProps.initialTime) {
            if (this.state.audio?.paused) {
                const { audio } = this.state;
                audio.currentTime = this.props.initialTime;
            }
        }
    }

    componentWillUnmount () {
        if (!this.state.audio?.paused) {
            this.state.audio?.pause();
        }
    }

    render () {
        const { style } = this.props;
        const { audio, paused, duration, progress, playbackRate, volume } = this.state;

        return (
            <Container style={style}>
                {audio && paused ? (
                    <PlayCircleOutlined
                        style={{
                            fontSize: "24px",
                            color: "#a8342b",
                        }}
                        onClick={() => {
                            audio.play();
                        }}
                    />
                ) : (
                    <PauseCircleOutlined
                        style={{
                            fontSize: "24px",
                            color: "#a8342b",
                        }}
                        onClick={() => {
                            audio.pause();
                        }}
                    />
                )}
                {audio && (
                    <span>
                        {String(Math.floor((progress * duration) / 60) || 0).padStart(
                            2,
                            "0",
                        )}
                        :
                        {String(Math.floor((progress * duration) % 60) || 0).padStart(
                            2,
                            "0",
                        )}
                    </span>
                )}
                <Slider
                    min={0}
                    max={1}
                    value={progress}
                    onChange={value => {
                        if (audio) {
                            audio.currentTime = value * duration;
                            this.setState({
                                progress: value,
                            });
                        }
                    }}
                    tooltip={{
                        formatter: value => {
                            if (audio) {
                                return `${String(
                                    Math.floor((value * duration) / 60) || 0,
                                ).padStart(2, "0")}:${String(
                                    Math.floor((value * duration) % 60) || 0,
                                ).padStart(2, "0")}`;
                            }
                            return "00:00";
                        },
                    }}
                    step={0.001}
                    style={{
                        width: "100%",
                    }}
                />
                {audio && (
                    <span>
                        {String(Math.floor(duration / 60) || 0).padStart(2, "0")}:
                        {String(Math.floor(duration % 60) || 0).padStart(2, "0")}
                    </span>
                )}
                {/* Playback Rate Slider */}
                <Popover
                    title="播放速度"
                    content={
                        <Row>
                            <Slider
                                min={0.2}
                                max={4}
                                value={playbackRate}
                                onChange={value => {
                                    if (audio) {
                                        audio.playbackRate = value;
                                    }
                                    this.setState({
                                        playbackRate: value,
                                    });
                                }}
                                step={0.1}
                                style={{
                                    width: "100%",
                                }}
                            />
                            <span>{playbackRate}x</span>
                        </Row>
                    }
                >
                    <HistoryOutlined
                        style={{
                            fontSize: "20px",
                            color: "#a8342b",
                        }}
                    />
                </Popover>
                {/* Sound Slider */}
                <Popover
                    title="音量"
                    content={
                        <Row>
                            <Slider
                                min={0}
                                max={1}
                                value={volume}
                                onChange={value => {
                                    if (audio) {
                                        audio.volume = value;
                                    }
                                    this.setState({
                                        volume: value,
                                    });
                                }}
                                step={0.01}
                                tipFormatter={value => {
                                    return `${Math.floor(value * 100)}%`;
                                }}
                                style={{
                                    width: "100%",
                                }}
                            />
                            <span>{Math.floor(volume * 100)}%</span>
                        </Row>
                    }
                >
                    <SoundOutlined
                        style={{
                            fontSize: "20px",
                            color: "#a8342b",
                        }}
                    />
                </Popover>
            </Container>
        );
    }
}