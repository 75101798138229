import { useState, useEffect, useMemo } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Dropdown, Modal, Empty, message } from "antd";

import { Page, Row, Col, Text } from "@/components/Base";
import RichTextPreview from "@/components/RichTextPreview";
import Audio from "@/components/Audio";

import { viewpoint } from "@/requests";

export default function ViewPointsDetailApp () {
    const navigate = useNavigate();
    const location = useLocation();
    const viewPointId = useParams().id;
    const [detail, setDetail] = useState({
        "reportId": null,
        "reportPath": [],
        "title": "",
        "content": "",
        "img": "",
        "mp3": "",
        "interval": "",
        "variety": null,
        "type": null,
        "date": "",
        "createdAt": "",
        "creatorId": null,
        "updatedAt": "",
        "updatedBy": null,
        "status": 0, // 0 created, 1 published, 2 deleted
        "permission": 0,
        "questionId": null,
        "creator": "陈 亮",
        "updater": "陈 亮",
        "reportName": null,
        // 上传的图片
        fileList: [],
        // 图表图片
        chatImages: [],
    });

    const formatRes = (res) => {
        if (res.img) {
            try {
                const allImgs = JSON.parse(res.img);
                res.fileList = allImgs.map((i, index) => ({
                    uid: "report_img" + index,
                    name: `image${index}.png`,
                    status: "done",
                    url: i.url,
                    thumbUrl: i.url,
                }));
            } catch (e){}
        }
        if (res.reportCardImg) {
            try {
                res.chatImages = JSON.parse(res.reportCardImg);
            } catch (e) {}
        }
        setDetail(res);
    };
    // 获取观点详情
    useEffect(() => {
        if (String(location.state?.viewpointDetail?.id) === String(viewPointId)) {
            // 使用 location.state.viewpointDetail 作为 detail
            formatRes(location.state.viewpointDetail);
        } else {
            // fallback
            viewpoint.detail(viewPointId)
                .then((res) => {
                    formatRes(res);
                })
                .catch((e) => {
                    console.error(e);
                    message.error("观点详情获取失败");
                });
        }
    }, [viewPointId]);

    const items = useMemo(() => {
        return [
            {
                "label": (
                    <Row gap={8} align="center" padding={4}>
                        <FontAwesomeIcon icon={solid("pencil")} style={{ position: "relative", fontSize: 12 }} fixedWidth />
                        <Text noWrap noShrink>编辑观点</Text>
                    </Row>
                ),
                "onClick": () => {
                    navigate(`/viewpoints/${viewPointId}/edit`, {
                        state: {
                            viewpointDetail: {
                                id: viewPointId,
                                ...detail,
                            },
                        },
                    });
                },
            },
            {
                "label": (
                    <Row gap={8} align="center" padding={4}>
                        <FontAwesomeIcon icon={solid("file-pen")} style={{ position: "relative", fontSize: 12 }} fixedWidth />
                        <Text noWrap noShrink>编辑观点细节</Text>
                    </Row>
                ),
                "onClick": () => {
                    navigate(`/viewpoints/${viewPointId}/edit-meta`, {
                        state: {
                            viewpointDetail: {
                                id: viewPointId,
                                ...detail,
                            },
                        },
                    });
                },
            },
            {
                "label": (
                    <Row gap={8} align="center" padding={4}>
                        <FontAwesomeIcon icon={solid("trash")} style={{ position: "relative", fontSize: 12 }} fixedWidth />
                        <Text noWrap noShrink>删除观点</Text>
                    </Row>
                ),
                "onClick": () => {
                    Modal.confirm({
                        title: "确认删除此观点？此操作无法被撤销！",
                        onOk: async (close) => {
                            try {
                                await viewpoint.delete(viewPointId);
                                message.success("操作成功");
                                navigate("/viewpoints");
                            } catch (e) {
                                console.error(e);
                                message.error("操作失败");
                            }
                            close();
                        },
                    });
                },
            },
        ];
    }, [viewPointId, detail]);

    return (
        <Page permission="viewpoint:query" align="flex-start" padding="0" minHeight="100%">
            <Row gap={8} align="center" justify="flex-start" padding={[12, 6]} background="var(--main-bg-color)">
                <FontAwesomeIcon icon={solid("angle-left")} fixedWidth style={{ position: "relative", top: 1, fontSize: 20 }} onClick={() => navigate("/viewpoints")} />
                <Col width="0" grow>
                    <Text weight="bold" size={20} noWrap ellipsis>{detail.title}</Text>
                    {detail.updater && (<Text size={12} noWrap ellipsis>{detail.updater} 编辑于 {detail.updatedAt}</Text>)}
                    {(!detail.updater && detail.creator) && (<Text size={12} noWrap ellipsis>{detail.creator} 创建于 {detail.createdAt}</Text>)}
                </Col>
                {detail.permission === 2 && (
                    <Dropdown
                        menu={{
                            items,
                        }}
                        trigger={["click"]}
                    >
                        <FontAwesomeIcon icon={solid("ellipsis")} fixedWidth style={{ position: "relative", top: 1, right: 6, fontSize: 20, justifySelf: "flex-end" }} />
                    </Dropdown>
                )}
            </Row>
            {(detail.mp3 || detail.content || detail.img) && (<Col gap={12} padding={12}>
                {detail.mp3 && (<Audio src={detail.mp3} />)}
                <RichTextPreview text={detail.content} />
                {
                    detail.chatImages?.map?.(i => <img src={i.url} alt="图表图片"
                                                    style={{width: "100%", objectFit: "contain"}}/>)
                }
                {
                    detail.fileList?.map?.(i => <img src={i.url} alt="观点图片"
                                             style={{width: "100%", objectFit: "contain"}}/>)
                }
            </Col>)}
            {!(detail.mp3 || detail.content || detail.img) && (
                <Row align="center" justify="center">
                    <Empty description="暂无内容" />
                </Row>
            )}
        </Page>
    );
}