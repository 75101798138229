import { useState, useEffect, useMemo } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Dropdown, Modal, Input, Upload, Button, message } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { eq } from "lodash";

import { Page, Row, Col, Text } from "@/components/Base";

import { viewpoint, file } from "@/requests";

const processContent = (content, reverse = false) => {
    if (reverse) {
        return content?.replace(/<br\s*\/*>/g, "\n") ?? "";
    } else {
        return content?.replace(/\n/g, "<br />") ?? "";
    }
};

export default function ViewPointsEditApp () {
    const navigate = useNavigate();
    const location = useLocation();
    const viewPointId = useParams().id;
    const [fileList, setFileList] = useState([]);
    const [chatImages, setChatImages] = useState([]);
    const [origin, setOrigin] = useState({});
    const [detail, setDetail] = useState({
        "reportId": null,
        "reportPath": [],
        "title": "",
        "content": "",
        "img": "",
        "mp3": "",
        "interval": "",
        "variety": null,
        "type": null,
        "date": "",
        "createdAt": "",
        "creatorId": null,
        "updatedAt": "",
        "updatedBy": null,
        "status": 0, // 0 created, 1 published, 2 deleted
        "questionId": null,
        "creator": "陈 亮",
        "updater": "陈 亮",
        "reportName": null,
    });

    // 获取观点详情
    useEffect(() => {
        if (String(location.state?.viewpointDetail?.id) === String(viewPointId)) {
            // 使用 location.state.viewpointDetail 作为 detail
            const d = {
                ...location.state.viewpointDetail,
                content: processContent(location.state.viewpointDetail.content, true),
            };
            setDetail(d);
            setOrigin(d);
            if (location.state.viewpointDetail.img) {
                const allImgs = JSON.parse(location.state.viewpointDetail.img);
                setFileList(allImgs.map((i, index) => ({
                    uid: "report_img" + index,
                    name: `image${index}.png`,
                    status: "done",
                    url: i.url,
                    thumbUrl: i.url,
                })));
            }
            if (location.state.viewpointDetail.reportCardImg) {
                setChatImages(JSON.parse(location.state.viewpointDetail?.reportCardImg));
            }
        } else {
            // fallback
            viewpoint.detail(viewPointId)
                .then((res) => {
                    const d = {
                        ...res,
                        content: processContent(res.content, true),
                    };
                    setDetail(d);
                    setOrigin(d);
                    if (res.img) {
                        setFileList([{
                            uid: "-1",
                            name: "image.png",
                            status: "done",
                            url: res.img,
                            thumbUrl: res.img,
                        }]);
                    }
                    if (res.img) {
                        const allImgs = JSON.parse(res.img);
                        setFileList(allImgs.map((i, index) => ({
                            uid: "report_img" + index,
                            name: `image${index}.png`,
                            status: "done",
                            url: i.url,
                            thumbUrl: i.url,
                        })));
                    }
                    if (res.reportCardImg) {
                        setChatImages(JSON.parse(res.reportCardImg));
                    }
                })
                .catch((e) => {
                    console.error(e);
                    message.error("观点详情获取失败");
                });
        }
    }, [viewPointId]);

    const items = useMemo(() => {
        return [
            {
                "label": (
                    <Row gap={8} align="center" padding={4}>
                        <FontAwesomeIcon icon={solid("file-pen")} style={{ position: "relative", fontSize: 12 }} fixedWidth />
                        <Text noWrap noShrink>编辑观点细节</Text>
                    </Row>
                ),
                "onClick": () => {
                    navigate(`/viewpoints/${viewPointId}/edit-meta`, {
                        state: {
                            viewpointDetail: {
                                id: viewPointId,
                                ...detail,
                            },
                        },
                    });
                },
            },
            {
                "label": (
                    <Row gap={8} align="center" padding={4}>
                        <FontAwesomeIcon icon={solid("trash")} style={{ position: "relative", fontSize: 12 }} fixedWidth />
                        <Text noWrap noShrink>删除观点</Text>
                    </Row>
                ),
                "onClick": () => {
                    Modal.confirm({
                        title: "确认删除此观点？此操作无法被撤销！",
                        onOk: async (close) => {
                            try {
                                await viewpoint.delete(viewPointId);
                                message.success("操作成功");
                                navigate("/viewpoints");
                            } catch (e) {
                                console.error(e);
                                message.error("操作失败");
                            }
                            close();
                        },
                    });
                },
            },
        ];
    }, [viewPointId, detail]);

    const handlePreview = (file) => {
        Modal.info({
            title: "预览",
            content: (
                <img
                    alt="example"
                    style={{ width: "100%" }}
                    src={file.url || file.response.url}
                />
            ),
        });
    };

    const handleChange = (info, type) => {
        // extract file from event
        const file = info.file;
        if (file) {
            if (type === "chat") {
                setChatImages(info.fileList);
            } else {
                setFileList(info.fileList);
            }
        }
    };

    const customRequest = options => {
        const { file: image, onSuccess, onError } = options;
        const key = Math.random();
        const done = message.loading({
            content: "正在上传...",
            key: key,
            duration: 0,
        });
        file.upload(image, (e) => {
            message.loading({
                content: `正在上传... ${Math.round(e.loaded / e.total * 100)}%`,
                key: key,
                duration: 0,
            });
        })
            .then(url => {
                onSuccess({ url }, file);
                message.success("上传成功");
            })
            .catch(err => {
                console.log(err);
                onError(err);
                message.error("上传失败");
            })
            .finally(() => {
                done();
            });
    };

    const handleDeleteViewPoint = () => {
        Modal.confirm({
            title: "确认删除此观点？此操作无法被撤销！",
            onOk: async (close) => {
                try {
                    await viewpoint.delete(viewPointId);
                    message.success("操作成功");
                    navigate("/viewpoints");
                } catch (e) {
                    console.error(e);
                    message.error("操作失败");
                }
                close();
            },
        });
    };

    const handleSelectOrDeleteRecord = () => {
        if (detail.mp3) {
            // delete
            Modal.confirm({
                title: "确认删除录音？",
                onOk: () => {
                    setDetail({
                        ...detail,
                        mp3: "",
                    });
                },
            });
        } else {
            // select
            const ele = document.createElement("input");
            ele.type = "file";
            ele.accept = "audio/3gpp, audio/3gpp2, audio/aiff, audio/x-aiff, audio/amr, audio/mp3, audio/mpeg3, audio/x-mp3, audio/x-mpeg3, audio/mp4, audio/mpeg, audio/x-mpeg, audio/wav, audio/x-wav, audio/x-m4a, audio/x-m4b, audio/x-m4p";
            ele.onchange = (e) => {
                const key = Math.random();
                const done = message.loading({
                    content: "正在上传...",
                    key: key,
                    duration: 0,
                });
                const audio = e.target.files[0];
                file.uploadAudioAndTranscode(audio, (e) => {
                    message.loading({
                        content: `正在上传... ${Math.round(e.loaded / e.total * 100)}%`,
                        key: key,
                        duration: 0,
                    });
                })
                    .then(url => {
                        setDetail({
                            ...detail,
                            mp3: url,
                        });
                        message.success("上传成功");
                    })
                    .catch(err => {
                        console.log(err);
                        message.error("上传失败");
                    })
                    .finally(() => {
                        done();
                    });
            };
            ele.click();
        }
    };

    const handleSave = async () => {
        try {
            await viewpoint.update({
                ...detail,
                id: viewPointId,
                content: processContent(detail.content),
                isCopy: 0,
                reportCardImg: JSON.stringify(chatImages.map((i) => ({
                    id: i.id,
                    url: i.url,
                }))),
                img: JSON.stringify(fileList.map((i) => ({
                    url: i.url || i.response?.url,
                    id: "report_img",
                }))),
            });
            navigate(`/viewpoints/${viewPointId}`);
            message.success("保存成功");
        } catch (e) {
            console.error(e);
            message.error("保存失败");
        }
    };

    const handleLeave = () => {
        if (eq(origin, detail)) {
            navigate(-1);
        } else {
            Modal.confirm({
                title: "您有未保存的修改，离开将会丢失修改，是否继续？",
                onOk: () => {
                    navigate(-1);
                },
            });
        }
    };

    useEffect(() => {
        console.log({filelist: fileList});
    }, [fileList]);

    return (
        <Page permission="viewpoint:update" align="flex-start" padding="0" minHeight="100%">
            <Row gap={8} align="center" justify="flex-start" padding={[12, 6]} background="var(--main-bg-color)">
                <FontAwesomeIcon icon={solid("angle-left")} fixedWidth style={{ position: "relative", top: 1, fontSize: 20 }} onClick={handleLeave} />
                <Col width="0" grow>
                    <Text weight="bold" size={20} noWrap ellipsis>{detail.title}</Text>
                    {detail.updater && (<Text size={12} noWrap ellipsis>{detail.updater} 编辑于 {detail.updatedAt}</Text>)}
                    {(!detail.updater && detail.creator) && (<Text size={12} noWrap ellipsis>{detail.creator} 创建于 {detail.createdAt}</Text>)}
                </Col>
                {detail?.permission === 2 ?
                    <Dropdown
                        menu={{
                            items,
                        }}
                        trigger={["click"]}
                    >
                        <FontAwesomeIcon icon={solid("ellipsis")} fixedWidth style={{ position: "relative", top: 1, right: 6, fontSize: 20, justifySelf: "flex-end" }} />
                    </Dropdown>
                    : null}
            </Row>
            <Col gap={8} padding={[0, 12]}>
                <Text weight="bold" size={14}>观点内容</Text>
                <Input.TextArea
                    value={detail.content}
                    onChange={(e) => {
                        setDetail({
                            ...detail,
                            content: e.target.value,
                        });
                    }}
                    autoSize={{ minRows: 6 }}
                />
            </Col>
            <Col gap={8} padding={[0, 12]}>
                <Upload
                    name="file"
                    accept="image/*"
                    listType="picture-card"
                    fileList={chatImages}
                    onPreview={handlePreview}
                    onChange={e => handleChange(e, "chat")}
                    disabled
                    itemRender={(originNode, file, fileList, { preview, remove }) => {
                        return (
                            <Col margin={[0, 8, 8, 0]} height={104} width={104}>
                                <Col padding={8} border="1px solid #d9d9d9" height={104} width={104} align="center" justify="center" onClick={preview}>
                                    <img src={file.url} alt={file.name} style={{ width: "100%" }} />
                                </Col>
                                <FontAwesomeIcon icon={solid("xmark")} style={{ position: "absolute", right: 0, top: 0, background: "rgba(0, 0, 0, 0.3)", color: "white", fontSize: 16 }} fixedWidth onClick={remove} />
                            </Col>
                        );
                    }}
                >
                    {!chatImages.length && (
                        <Col gap={8} height="100%" align="center" justify="center">
                            <Text>尚未选择图表</Text>
                        </Col>
                    )}
                </Upload>
            </Col>
            <Col gap={8} padding={[0, 12]}>
                <Upload
                    name="file"
                    accept="image/*"
                    listType="picture-card"
                    fileList={fileList}
                    onPreview={handlePreview}
                    onChange={handleChange}
                    customRequest={customRequest}
                    itemRender={(originNode, file, fileList, { preview, remove }) => {
                        return (
                            <Col margin={[0, 8, 8, 0]} height={104} width={104}>
                                <Col padding={8} border="1px solid #d9d9d9" height={104} width={104} align="center" justify="center" onClick={preview}>
                                    <img src={file.thumbUrl} alt={file.name} style={{ width: "100%" }} />
                                </Col>
                                <FontAwesomeIcon icon={solid("xmark")} style={{ position: "absolute", right: 0, top: 0, background: "rgba(0, 0, 0, 0.3)", color: "white", fontSize: 16 }} fixedWidth onClick={remove} />
                            </Col>
                        );
                    }}
                >
                    {fileList.length < 6 && (
                        <Col gap={8} height="100%" align="center" justify="center">
                            <PlusOutlined />
                            <Text>点击上传</Text>
                        </Col>
                    )}
                </Upload>
            </Col>
            <Col gap={8} padding={[0, 12]}>
                <Button type={Boolean(detail.mp3) ? "dashed" : "default"} danger={Boolean(detail.mp3)} onClick={handleSelectOrDeleteRecord}>{detail.mp3 ? "删除录音" : "上传录音"}</Button>
                <Row gap={8}>
                    <Button type={Boolean(detail.mp3) ? "dashed" : "default"} danger style={{ width: "100%" }} onClick={handleDeleteViewPoint}>删除观点</Button>
                    <Button type="primary" style={{ width: "100%" }} onClick={handleSave}>保存观点</Button>
                </Row>
            </Col>
        </Page>
    );
}