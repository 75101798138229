import React, {useEffect, useMemo, useState} from "react";
import {Button, Checkbox, message, Modal, Select} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {cloneDeep, debounce} from "lodash";
import moment from "moment";

import withRouter from "@/components/withRouter";

import {Col, Page, Row, Text} from "@/components/Base";
import DatePicker from "@/components/DatePicker";
import Cascader from "@/components/Cascader";

import {viewpoint} from "@/requests";

import {INTERVAL_OPTIONS, PICKER_CONFIG} from "@/pages/viewpoints/constants";
import {formatPermissionTree} from "@/utils/viewpoint";
import {useRequest} from "ahooks";
import {ViewpointRoleMap} from "@/utils/constant";
import {findTreeNodeByKey} from "@/utils/tree";
import { Cascader as OCascader } from "antd-mobile";

const ViewpointCreateApp = function ({ router }) {
    const [loading, setLoading] = useState(false);

    // 选项
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [reportOptions, setReportOptions] = useState([]);

    // 表单
    const [interval, setInterval] = useState(INTERVAL_OPTIONS[0].value);
    const [date, setDate] = useState(null);
    const [category, setCategory] = useState([]);
    const [report, setReport] = useState([]);
    const [copy, setCopy] = useState(false);
    const [role, setRole] = useState();

    const {
        data: searchOptionsData,
    } = useRequest(() => viewpoint.getReportTypeList({
        type: "AUTH_TYPE_VIEWPOINT",
    }));

    const roleSelectOptions = useMemo(() => {
        return searchOptionsData?.data?.roleList?.map((v) => ({
            label: ViewpointRoleMap[v],
            value: v,
        }));
    }, [searchOptionsData]);

    // 提交
    const submit = async () => {
        if (!date) {
            message.error("请选择日期");
            return;
        }
        if (!category.length) {
            message.error("请选择分类");
            return;
        }
        if (!role) {
            message.error("请选择角色");
            return;
        }
        if (interval === "weekly") {
            const weekday = moment().weekday();
            const weekOffset = moment(date).locale("zh-cn").week() - moment().locale("zh-cn").week();
            // 周一、周二、周三创建本周观点时
            // 或 正在创建未来观点时 进行提示
            if (([0, 1, 2].includes(weekday) && weekOffset === 0) || weekOffset > 0) {
                try {
                    await new Promise((resolve, reject) => {
                        Modal.confirm({
                            title: "提示",
                            content: `您正在创建一个${weekOffset === 0 ? "本" : `未来 ${weekOffset} `
                                }周的周观点，这个时间正确吗？`,
                            okText: "继续创建",
                            cancelText: "重新选择",
                            onOk: () => {
                                resolve();
                            },
                            onCancel: () => {
                                reject();
                            },
                        });
                    });
                } catch (e) {
                    return;
                }
            }
        }
        setLoading(true);
        const title = `${interval.includes("daily")
            ? moment(date).locale("zh-cn").format("YYYY-MM-DD")
            : interval === "weekly"
                ? `${moment(date).locale("zh-cn").format("YYYY-MM-DD")}-${moment(date).locale("zh-cn").add(7, "days").format("YYYY-MM-DD")}`
                : interval === "monthly"
                    ? moment(date).locale("zh-cn").format("YYYY-MM")
                    : moment(date).locale("zh-cn").format("YYYY")
            }-${categoryOptions.find(c => c.value === category[0]).label}-${categoryOptions.find(c => c.value === category[0]).children.find(collection => collection.value === category[1]).label}`;
        viewpoint
            .create({
                interval,
                title,
                role,
                date: date.format("YYYY-MM-DD"),
                variety: category[0],
                classification: category[1],
                reportId: report[report.length - 1],
                reportPath: JSON.stringify(report?.slice(0, report?.length - 1)),
                isCopy: copy ? 1 : 0,
            })
            .then((res) => {
                if (res?.id) {
                    message.success("创建成功");
                    router.navigate(`/viewpoints/${res.id}/edit`);
                } else {
                    message.error("创建失败");
                }
            })
            .catch((e) => {
                console.log(e);
                message.error("创建失败");
            })
            .finally(() => setLoading(false));
    };

    // 动态加载报告
    const loadReports = useMemo(() => debounce(selectedOptions => {
        const targetId =
            selectedOptions[selectedOptions.length - 1];
        const targetOption = findTreeNodeByKey({value: targetId, treeData: reportOptions});
        if (targetOption?.children) {
            const tempChildren = targetOption?.children || [];
            targetOption.children = OCascader.optionSkeleton;
            setReportOptions(() => [...reportOptions]);
            viewpoint.getReportList({
                collectionId: targetId,
            })
                .then(res => {
                    targetOption.children = res.rows.reduce((old, report) => {
                        if (
                            !old.some(
                                (item) =>
                                    item.value === report.id,
                            )
                        ) {
                            old.push({
                                value: report.id,
                                label: report.name,
                                isLeaf: true,
                            });
                        }
                        return old;
                    }, cloneDeep(tempChildren));
                    setReportOptions(() => [...reportOptions]);
                })
                .catch(error => {
                    console.log(error);
                    message.error("获取报告列表失败");
                });
        }
    }, 500), [reportOptions]);

    // 获取分类和报告
    useEffect(() => {
        viewpoint
            .getCollectionPermissionTreeForSave({type: "AUTH_TYPE_VIEWPOINT"})
            .then((res) => {
                setCategoryOptions(formatPermissionTree(res?.data || []));
            });
        viewpoint.getCollectionPermissionTreeForSave({type: "AUTH_TYPE_DASHBOARD"})
            .then(res => {
                setReportOptions(formatPermissionTree(res?.data || [], {withSkeleton: true}));
            });
    }, []);

    return (
        <Page permission="viewpoint:insert" align="flex-start">
            <Row gap={8} align="center" justify="flex-start" padding={[12, 0]} width="auto" onClick={() => router.navigate(-1)}>
                <FontAwesomeIcon icon={solid("angle-left")} fixedWidth style={{ position: "relative", top: 1, fontSize: 20 }} />
                <Text weight="bold" size={20}>创建观点</Text>
            </Row>
            <Col gap={8} justify="center">
                <Text weight="bold" size={14} noShrink>
                    选择类型
                    <Text color="red">*</Text>
                </Text>
                <Select
                    style={{ width: "100%" }}
                    options={INTERVAL_OPTIONS}
                    value={interval}
                    onChange={setInterval}
                />
            </Col>
            <Col gap={8} justify="center">
                <Text weight="bold" size={14} noShrink>
                    选择日期
                    <Text color="red">*</Text>
                </Text>
                <DatePicker
                    style={{ width: "100%" }}
                    picker={PICKER_CONFIG[interval].picker}
                    value={date}
                    onChange={(date) => {
                        setDate(date.startOf(PICKER_CONFIG[interval].format));
                    }}
                />
            </Col>
            <Col gap={8} justify="center">
                <Text weight="bold" size={14} noShrink>
                    选择角色
                    <Text color="red">*</Text>
                </Text>
                <Select
                    placeholder="请选择角色"
                    style={{
                        width: "100%",
                    }}
                    options={roleSelectOptions}
                    value={role}
                    onChange={setRole}
                />
            </Col>
            <Col gap={8} justify="center">
                <Text weight="bold" size={14} noShrink>
                    选择品种
                    <Text color="red">*</Text>
                </Text>
                <Cascader
                    changeOnSelect
                    placeholder="请选择品种"
                    style={{
                        width: "100%",
                    }}
                    options={categoryOptions}
                    value={category}
                    onChange={setCategory}
                />
            </Col>
            <Col gap={8} justify="center">
                <Text weight="bold" size={14} noShrink>
                    关联报告
                </Text>
                <Cascader
                    changeOnSelect
                    placeholder="请选择报告"
                    style={{ width: "100%" }}
                    options={reportOptions}
                    value={report}
                    onChange={setReport}
                    loadData={loadReports}
                />
            </Col>
            <Row justify="flex-end">
                <Checkbox
                    checked={copy}
                    onChange={e => setCopy(e.target.checked)}
                >
                    复制报告内容
                </Checkbox>
            </Row>
            <Row justify="flex-end">
                <Button loading={loading} type="primary" style={{ width: "100%" }} onClick={submit}>创建</Button>
            </Row>
        </Page>
    );
};

export default withRouter(ViewpointCreateApp);